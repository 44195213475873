import React, { useEffect, useRef, useState } from "react";
import instance from "../../axios";
import { Input, Table } from "antd";
import dayjs from "dayjs";
import { useExportExcel } from "../../hooks/useExportExcel";
import { ButtonExportToExcel } from "../../components/ButtonExportToExcel/ButtonExportToExcel";

const ROWS = 10;

export default function NewHeweDBManage() {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const isSearchMode = useRef(false);

  const { isLoading: isPendingExportExcel, handleExportFileExcel } =
    useExportExcel({
      serviceGetData: () =>
        instance.get(
          `/getHeweDBData?limit=${total}&page=${page}&keyword=${keyword}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        ),
    });

  const handleClickExportBtn = () => {
    handleExportFileExcel({
      sheetName: "HEWE DB",
      headers: [
        { colName: "Email" },
        { colName: "Username" },
        { colName: "HEWE Amount" },
        { colName: "HEWE Price" },
        { colName: "USDT for HEWE" },
        { colName: "AMC Amount" },
        { colName: "AMC Price" },
        { colName: "USDT for AMC" },
        { colName: "USDT Total" },
        { colName: "Loan Acceptance (%)" },
        { colName: "USDT Received" },
        { colName: "Time Start" },
        { colName: "Time End" },
        { colName: "Time Left" },
        { colName: "Status" },
      ],

      onlyGetFieldData: [
        "userEmail",
        "userName",
        "hewe",
        "priceHewe",
        "usdthewe",
        "amc",
        "priceAmc",
        "usdtamc",
        "totalUSDT",
        "percent",
        "receivedUSDT",
        "createdAt",
        "endTime",
        "timeLeft",
        "status",
      ],
      preprocessColumnsData: {
        hewe: (record) => roundDisplay(record.hewe),
        priceHewe: (record) => record.priceHewe,
        usdthewe: (record) => roundDisplay(record.usdthewe),
        amc: (record) => roundDisplay(record.amc),
        priceAmc: (record) => record.priceAmc,
        usdtamc: (record) => roundDisplay(record.usdtamc),
        totalUSDT: (record) => roundDisplay(record.usdtamc + record.usdthewe),
        percent: (record) => record.percent,
        receivedUSDT: (record) => roundDisplay(record.receivedUSDT),
        createdAt: (record) => dayjs(record.createdAt).format("DD/MM/YYYY"),
        endTime: (record) => dayjs(record.endTime).format("DD/MM/YYYY"),
        timeLeft: (record) => dayjs(record.endTime).diff(dayjs(), "days"),
        status: (record) => {
          if (record.status === "inprocess") return "Active";
          else if (record.status === "completed") return "Completed";
        },
      },
    });
  };

  const handleChangeKeyword = (e) => {
    isSearchMode.current = true;
    setKeyword(e.target.value);
    setPage(1);
  };

  const getData = async (limit, page) => {
    setLoading(true);
    try {
      const res = await instance.get(
        `/getHeweDBData?limit=${limit}&page=${page}&keyword=${keyword}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setListData(res.data.data.array);
      setTotal(res.data.data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const roundDisplay = (value) => {
    if (Number.isInteger(value)) {
      return Number(value).toLocaleString("en-US").replaceAll(",", " ");
    } else
      return Number(value)
        .toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        .replaceAll(",", " ");
  };

  const columns = [
    {
      title: "User",
      render: (_, record) => {
        return (
          <div>
            <p>{record.userEmail}</p>
            <p>{record.userName}</p>
          </div>
        );
      },
    },
    {
      title: "HEWE",
      render: (_, record) => {
        return (
          <div>
            <p>HEWE: {roundDisplay(record.hewe)}</p>
            <p>Price: {record.priceHewe}</p>
            <p>USDT: {roundDisplay(record.usdthewe)}</p>
          </div>
        );
      },
    },
    {
      title: "AMC",
      render: (_, record) => {
        return (
          <div>
            <p>AMC: {roundDisplay(record.amc)}</p>
            <p>Price: {record.priceAmc}</p>
            <p>USDT: {roundDisplay(record.usdtamc)}</p>
          </div>
        );
      },
    },
    {
      title: "USDT",
      render: (_, record) => {
        return (
          <div>
            <p>Total: {roundDisplay(record.usdtamc + record.usdthewe)}</p>
            <p>Percent: {record.percent}%</p>
            <p>Received: {roundDisplay(record.receivedUSDT)}</p>
          </div>
        );
      },
    },
    {
      title: "Time",
      render: (_, record) => {
        return (
          <div>
            <p>Start: {dayjs(record.createdAt).format("DD/MM/YYYY")}</p>
            <p>End: {dayjs(record.endTime).format("DD/MM/YYYY")}</p>
            <p>Time left: {dayjs(record.endTime).diff(dayjs(), "days")} days</p>
          </div>
        );
      },
    },
    {
      title: "Status",
      render: (_, record) => {
        if (record.status === "inprocess")
          return <p style={{ color: "orange", fontWeight: "bold" }}>Active</p>;
        else if (record.status === "completed")
          return (
            <p style={{ color: "green", fontWeight: "bold" }}>Completed</p>
          );
      },
    },
  ];

  useEffect(() => {
    setPage(1);
    getData(ROWS, 1);
  }, []);

  useEffect(() => {
    if (!isSearchMode.current) return;

    const timeout = setTimeout(() => {
      getData(ROWS, 1);
      isSearchMode.current = false;
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [keyword]);

  return (
    <>
      <h4>HEWE DB Transaction</h4>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "8px",
        }}
      >
        <Input
          style={{ maxWidth: "350px" }}
          value={keyword}
          onChange={handleChangeKeyword}
          placeholder="Search..."
        />
        <ButtonExportToExcel
          loading={isPendingExportExcel}
          onExport={handleClickExportBtn}
        />
      </div>

      <Table
        dataSource={listData}
        columns={columns}
        rowKey={(record) => record._id}
        size="middle"
        scroll={{ x: 900 }}
        loading={loading}
        pagination={{
          position: ["topRight"],
          size: "default",
          total,
          current: page,
          onChange: (page) => {
            setPage(page);
            getData(ROWS, page);
          },
          showSizeChanger: false,
          showQuickJumper: false,
          pageSize: ROWS,
        }}
      />
    </>
  );
}
