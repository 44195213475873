export const API_URL = "https://hewe.io/api/admin";
export const URL = "https://hewe.io";
// export const API_URL = "http://192.168.1.20:3000/api/admin";
// export const URL = "http://192.168.1.20:3000";

// export const API_URL = "https://pgapi.hewe.io/api/admin";
// export const API_URL = "https://hewe.io/api/admin";
// export const API_URL = "http://192.168.1.13:3007/api/admin";
// export const API_URL = "https://vapi.hewe.io/api/admin";
export const GOOGLE_MAP_API_KEY = "AIzaSyANFvytlUdoF9WVD7zmQm5N9UhTF2FSjjc";
export const ADMIN_EMAIL = "amcblockchain@gmail.com";
